'use client'

import React, { useEffect, useRef, useState } from 'react'

import { ArrowRight } from 'iconsax-react';

import { GrabSlider } from "@/core/helpers/helper";

import Image from '@/core/components/Image'

import IconProductPrimary from '@/images/icons/IconProductPrimary.webp';
import IconProductSecondary from '@/images/icons/IconProductSecondary.webp';
import IconProductTakeover from '@/images/icons/IconProductTakeover.png';
import IconProductMultiguna from '@/images/icons/IconProductMultiguna.webp';
import IconProductIDEALScore from '@/images/icons/IconProductIDEALScore.webp';
import IconProductIDEALPay from '@/images/icons/IconProductIDEALPay.webp';

export default function PropertyCategory({
    enableIdealPay = 1,
}) {

    const [getIsClicked, setIsClicked] = useState(false);
    const [getIsDrag, setIsDrag] = useState(false);

    const listService = [
        {
            id: 0,
            arrowColor: '#E3F9F6',
            backgroundColor: 'bg-success-10',
            description: <>Kepikiran beli rumah baru tahun ini?<br />Cek dulu nih partner properti di IDEAL App</>,
            href: '/product/primary',
            icon: IconProductPrimary,
            show: 1,
            textColor: 'text-primary-90',
            title: 'Primary KPR',
            titleBackgroundColor: 'bg-primary-90',
            titleColor: 'text-success-10',
        },
        {
            id: 1,
            arrowColor: '#403865',
            backgroundColor: 'bg-secondary-60',
            description: 'Beli rumah pakai KPR, masih harus ke Bank? Apply KPR lewat IDEAL aja, tanpa ribet!',
            href: '/product/secondary',
            icon: IconProductSecondary,
            show: 1,
            textColor: 'text-secondary-10',
            title: 'Secondary KPR',
            titleBackgroundColor: 'bg-secondary-20',
            titleColor: 'text-secondary-60',
        },
        {
            id: 2,
            arrowColor: '#195E93',
            backgroundColor: 'bg-success-60',
            description: <>Mau cicilan KPR rumah lebih hemat?<br />Pindah Bank KPR aja lewat IDEAL Takeover!</>,
            href: '/product/takeover',
            icon: IconProductTakeover,
            show: 1,
            textColor: 'text-info-20',
            title: 'Take Over Pinjaman',
            titleBackgroundColor: 'bg-info-20',
            titleColor: 'text-success-60',
        },
        {
            id: 3,
            arrowColor: '#3B3B52',
            backgroundColor: 'bg-warning-60',
            description: 'Dompet udah krisis, kebutuhan makin naik drastis? Jaminkan saja sertifikat propertimu',
            href: '/product/multiguna',
            icon: IconProductMultiguna,
            show: 1,
            textColor: 'text-warning-30',
            title: 'Pinjaman Multiguna',
            titleBackgroundColor: 'bg-warning-30',
            titleColor: 'text-warning-60',
        },
        {
            id: 4,
            arrowColor: '#E3F9F6',
            backgroundColor: 'bg-primary-10',
            description: <>Mau tau seberapa besar limit KPR yang bisa kamu dapat?<br />Cek pakai ini yuk!</>,
            href: '/product/ideal-pass',
            icon: IconProductIDEALScore,
            show: 1,
            textColor: 'text-primary-90',
            title: 'IDEAL Pass',
            titleBackgroundColor: 'bg-primary-60',
            titleColor: 'text-success-10',
        },
        {
            id: 5,
            arrowColor: '#F7F6FE',
            backgroundColor: 'bg-secondary-25',
            description: <>Bayar kosan malah bisa dapet cashback sampai miles??<br />Cobain pakai IDEAL Pay!</>,
            href: '/ideal-pay',
            icon: IconProductIDEALPay,
            show: enableIdealPay,
            textColor: 'text-secondary-80',
            title: 'IDEAL Pay',
            titleBackgroundColor: 'bg-linear-to-r from-secondary-70 to-secondary-30',
            titleColor: 'text-secondary-10',
        },
    ]

    const refServices = useRef(null);

    useEffect(() => {
        if (refServices?.current) GrabSlider(refServices?.current);
    }, [refServices]);

    return (
        <section className='relative w-full px-4 pb-[72px] xl:pb-[132px]' aria-label="Property Categories">
            <div ref={refServices} className="cursor-grab inline-flex gap-6 hide-scrollbar items-center justify-between overflow-auto pl-[calc(50%-640px)] pr-[calc(50%-640px)] relative w-full" role="list">
                {listService.filter(item => item.show).map(item => {
                    return (
                        <article key={item?.id} className='max-w-[324px] min-w-[324px] relative py-4'>
                            <div className='px-6 relative'>
                                <div className={`${item?.backgroundColor} h-[490px] relative rounded-2xl shadow-[0px_4px_10px_#00000033]`}>
                                    <div className='p-8 relative'>
                                        <h6 className={`font-medium ${item?.textColor}`}>{item?.description}</h6>
                                    </div>
                                    <div className='absolute bottom-0'>
                                        <Image src={item?.icon} alt={item?.title} height={280} loading='lazy' quality={75} width={280} />
                                    </div>
                                </div>
                            </div>
                            <button type='button' className={`absolute ${item?.titleBackgroundColor} bottom-10 flex gap-6 items-center justify-between px-6 py-7 rounded-xl w-full`} onMouseUp={() => {
                                if (!getIsDrag) {
                                    window.open(item?.href);
                                } else {
                                    setIsClicked(false);
                                    setIsDrag(false);
                                }
                            }} onMouseMove={() => {
                                if (getIsClicked) setIsDrag(true);
                            }} onMouseDown={() => {
                                setIsClicked(true);
                                setIsDrag(false);
                            }}>
                                <h6 className={`font-medium ${item?.titleColor}`}>{item?.title}</h6>
                                <ArrowRight color={item?.arrowColor} size={24} />
                            </button>
                        </article>
                    )
                })}
            </div>
        </section>
    )
}
